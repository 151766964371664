/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  formatCalendarDetailsOrderWithoutHour,
  formatDateTime,
  scrollToTop,
  translateOrderState,
} from "../utils/functions";
import { useEffect, useState } from "react";
import Modal from "../components/modals/GenericModal";
import tropiPayLogo from "../assets/png/logoTropiPay.png";
import LoadingSpin from "../components/misc/LoadingSpin";
import { setTropiPayURL } from "../store/slices/FormSlice";
import { useServerOrders } from "../services/useServerOrders";
import { setInitProductsCar } from "../store/slices/InitSlice";
import ImageComponent from "../components/misc/Image";

const PaymentCompleted = () => {
  const navigate = useNavigate();

  const { CancelOrder, CreateTropipayLink } = useServerOrders();

  const { tropiPayURL } = useAppSelector((state) => state.form);

  const dispatch = useAppDispatch();

  const { orderCompleted } = useAppSelector((state) => state.orders);

  const [tropiPayLoading, setTropiPayLoading] = useState<boolean>(false);
  const [redirectTropipay, setRedirectTropipay] = useState<boolean>(false);

  const [isIOS, setIsIOS] = useState<boolean>(false);

  const [deleteOrderModal, setDeleteOrderModal] = useState<{
    state: boolean;
    id: number | null;
  }>({
    state: false,
    id: null,
  });

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    const isIOSUserAgent = //@ts-ignore
      /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    setIsIOS(isIOSUserAgent && isSafari);

    scrollToTop();
  }, []);

  useEffect(() => {
    if (tropiPayURL !== null) {
      if (tropiPayURL === "tropiPayLinkLoading") {
        setTropiPayLoading(true);
      } else {
        setTimeout(() => {
          if (isIOS) {
            setRedirectTropipay(true);
          } else {
            window.open(tropiPayURL);

            dispatch(setTropiPayURL(null));
          }
        }, 2000);
      }
    } else {
      setTropiPayLoading(false);
    }
  }, [tropiPayURL]);

  return (
    <>
      <main className="flex justify-center items-center w-full lg:min-h-full">
        <div>


          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">

            <div className="lg:col-start-2">
              <ImageComponent
                className="mx-auto h-22 w-56 "
                src={"/El-Gelato-Logo-Colores-1.png"}
                hash={"LXMts[odxuW=%LWVj[j[~BWXV@n$"}
              />
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Gracias por ordenar
              </p>
              <p className="mt-2 text-base text-gray-500">
                Gracias por tu pedido. Estamos procesándolo en este momento y pronto te enviaremos la confirmación. ¡Agradecemos tu paciencia!
              </p>

              <dl className="mt-16 text-sm font-medium">
                <dt className="text-gray-900">Número de orden</dt>
                <dd className="mt-2 text-indigo-600">
                  {orderCompleted?.operationNumber}
                </dd>
              </dl>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
              >
                {orderCompleted?.selledProducts.map((product) => (
                  <li key={product.id} className="flex space-x-6 py-6">
                    <img
                      src={product.image?.src}
                      alt={product.image?.thumbnail}
                      className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                    />
                    <div className="flex-auto space-y-1">
                      <p>{product.quantity} U</p>
                      <h3 className="text-gray-900">
                        <p>{product.name}</p>
                      </h3>
                      {/* <p>{product.color}</p> */}
                      {/* <p>{product.size}</p> */}
                    </div>
                    <p className="flex-none font-medium text-gray-900">
                      {product.priceTotal.amount.toFixed(2) +
                        " " +
                        product.priceTotal.codeCurrency}
                    </p>
                  </li>
                ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  {orderCompleted?.prices.map((price) => (
                    <dd className="text-gray-900">
                      {price.price.toFixed(2) + " " + price.codeCurrency}
                    </dd>
                  ))}
                </div>

                {orderCompleted?.shippingPrice !== null && (
                  <div className="flex justify-between">
                    <dt>Envío</dt>
                    <dd className="text-gray-900">
                      {orderCompleted?.shippingPrice.amount.toFixed(2) +
                        " " +
                        orderCompleted?.shippingPrice.codeCurrency}
                    </dd>
                  </div>
                )}

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  {orderCompleted?.totalToPay.map((price) => (
                    <dd className="text-gray-900">
                      {price.amount.toFixed(2) + " " + price.codeCurrency}
                    </dd>
                  ))}
                </div>
              </dl>

              <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                {!orderCompleted?.pickUpInStore && (
                  <div>
                    <dt className="font-medium text-gray-900">
                      Dirección de envío
                    </dt>
                    <dd className="mt-2">
                      <address className="not-italic">
                        <span className="block">
                          {(orderCompleted?.shipping?.street_1
                            ? orderCompleted?.shipping?.street_1 + ", "
                            : "") +
                            (orderCompleted?.shipping?.street_2
                              ? orderCompleted?.shipping?.street_2 + ", "
                              : "") +
                            (orderCompleted?.shipping?.city
                              ? orderCompleted?.shipping.city + ", "
                              : "") +
                            (orderCompleted?.shipping?.municipality?.name
                              ? orderCompleted?.shipping.municipality.name +
                              ", "
                              : "") +
                            (orderCompleted?.shipping?.province?.name
                              ? orderCompleted?.shipping?.province?.name + ", "
                              : "") +
                            (orderCompleted?.shipping?.country?.name
                              ? orderCompleted?.shipping?.country?.name
                              : "")}{" "}
                        </span>
                      </address>
                    </dd>
                  </div>
                )}

                <div>
                  <dt className="font-medium text-gray-900">
                    {`${orderCompleted?.pickUpInStore
                      ? "Recogida en tienda: "
                      : "Entrega: "
                      } `}
                  </dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    <p className="text-gray-900">{
                      formatDateTime(orderCompleted?.deliveryAt)
                    }</p>
                  </dd>
                </div>

                <div
                  className={`${!orderCompleted?.pickUpInStore ? "mt-8" : ""}`}
                >
                  <dt className="font-medium text-gray-900">
                    Estado de la orden
                  </dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    <p className="text-gray-900">
                      {translateOrderState(orderCompleted?.status!)}
                    </p>
                  </dd>
                </div>
                <div className="mt-8">
                  <dt className="font-medium text-gray-900">Método de pago</dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    <p className="text-gray-900">
                      {orderCompleted?.paymentGateway?.name!}
                    </p>
                  </dd>
                </div>
                {orderCompleted?.customerNote && (
                  <div className="mt-8">
                    <dt className="font-medium text-gray-900">
                      Notas del cliente
                    </dt>
                    <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                      <p className="text-gray-900">
                        {orderCompleted?.customerNote!}
                      </p>
                    </dd>
                  </div>
                )}
              </dl>

              <div className="w-full flex justify-between items-center">
                {(orderCompleted?.status === "PAYMENT_PENDING" ||
                  orderCompleted?.status === "CREATED") && (
                    <div className="mt-6 sm:mt-4 flex items-center justify-end ml-4">
                      <button
                        type="button"
                        className="flex w-32 sm:w-40 whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-600 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                        onClick={() => {
                          // CancelOrder(order.id as number)
                          setDeleteOrderModal({
                            state: true,
                            id: orderCompleted?.id,
                          });
                        }}
                      >
                        Cancelar orden
                      </button>
                    </div>
                  )}

                {orderCompleted?.paymentGateway?.code === "G_TROPIPAY" && (
                  <div className="mt-6 sm:mt-4 flex items-center justify-end ml-4">
                    <button
                      type="button"
                      className="flex w-normal whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                      onClick={() => {
                        CreateTropipayLink(orderCompleted.id);
                      }}
                    >
                      Volver a generar link de pago
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-16 border-t border-gray-200 py-6 text-right flex items-center justify-between">
                <Link
                  to={"/orders_historial"}
                  className="font-semibold text-blue-500 cursor-pointer"
                  onClick={() => {
                    scrollToTop()
                    dispatch(setInitProductsCar([]));
                  }}
                >
                  Ver historial de ordenes
                </Link>

                <Link
                  to={"/"}
                  className="font-semibold text-blue-500 cursor-pointer"
                  onClick={() => {
                    scrollToTop()
                    dispatch(setInitProductsCar([]));
                  }}
                >
                  Continuar comprando
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      {tropiPayLoading && (
        <Modal
          state={tropiPayLoading}
          close={() => {
            setTropiPayLoading(false);
          }}
        >
          <div className="flex items-center flex-col">
            <img src={tropiPayLogo} alt="tropiPayLogo" className="h-8 w-auto" />

            <LoadingSpin color="black" />

            <p className="text-center mt-4">
              Redireccionando a TropiPay para ejecutar el pago
            </p>
          </div>
        </Modal>
      )}

      {redirectTropipay && (
        <Modal
          state={redirectTropipay}
          close={() => {
            dispatch(setTropiPayURL(null));
            setRedirectTropipay(false);
          }}
        >
          <div className="flex items-center flex-col">
            <img src={tropiPayLogo} alt="tropiPayLogo" className="h-8 w-auto" />

            <a
              href={tropiPayURL!}
              target="_blank"
              rel="noopener noreferrer"
              className="p-4 bg-yellow-400 mt-4 rounded-md text-white cursor-pointer"
              onClick={() => {
                dispatch(setTropiPayURL(null));
                setRedirectTropipay(false);
              }}
            >
              Ir a TropiPay
            </a>
          </div>
        </Modal>
      )}

      {deleteOrderModal.state && (
        <Modal state={deleteOrderModal.state} close={setDeleteOrderModal}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              ¿Seguro que desea cancelar la orden?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setInitProductsCar([]));
                CancelOrder(deleteOrderModal?.id!);
                setDeleteOrderModal({
                  state: false,
                  id: null,
                });
                navigate("/");
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PaymentCompleted;
