/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../store/hooks";
import imageDefault from "../assets/jpg/no-imagen-shop.jpg"
import { filterOrdenStatus, formatDateTime, printPriceWithCommasAndPeriods } from "../utils/functions";
import { useServerOrders } from "../services/useServerOrders";
import Modal from "../components/modals/GenericModal";
import LoadingSpin from "../components/misc/LoadingSpin";
import { Link, useNavigate } from "react-router-dom";
import ImageComponent from "../components/misc/Image";


const OrdersHistory = () => {

  const navigate = useNavigate()

  const { orders } = useAppSelector(state => state.orders)
  const { CancelOrder, CreateTropipayLink, getOrderDetails, getMyOrders, isLoading } = useServerOrders()

  const [deleteOrderModal, setDeleteOrderModal] = useState<{ state: boolean, id: number | null }>({
    state: false,
    id: null
  });


  const [filter] = useState<
    Record<string, string | number | boolean>
  >({ per_page: 5 });


  useEffect(() => {
    getMyOrders({ ...filter });
  }, [filter]);


  return (
    <>
      <div className="mx-auto max-w-7xl sm:px-2 lg:px-8 mt-40 mb-40">
        <ImageComponent
          className="mx-auto h-22 w-56"
          src={"/El-Gelato-Logo-Colores-1.png"}
          hash={"LXMts[odxuW=%LWVj[j[~BWXV@n$"}
        />
        <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
          <div className="flex justify-between mt-20">
            <h2 className="text-3xl font-semibold ">Historial de órdenes</h2>

            <Link
              to={"/"}
              className="font-semibold text-blue-500 cursor-pointer"
            >
              Regresar a la tienda
              <FontAwesomeIcon
                icon={
                  faArrowRight
                }
                className="ml-1 text-blue-500"
                size="sm"
                aria-hidden="true"
              />
            </Link>
          </div>
          <p className="text-gray-400">Compruebe el estado de los pedidos recientes, gestione las devoluciones y descubra productos similares.</p>

          {
            isLoading
              ? <div className="w-full h-96 flex justify-center items-center">
                <LoadingSpin color="black" />
              </div>
              : <div className="mt-10 space-y-16 ">
                {orders?.map((order) => (
                  <section className="ring-1 ring-slate-200 rounded-md py-4 px-4" key={order.operationNumber} aria-labelledby={`${order.operationNumber}-heading`}>
                    <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                      <div className="flex justify-between md:block">
                        <dt className="font-medium text-gray-900">Orden</dt>
                        <dd className="md:mt-1">{order.operationNumber}</dd>
                      </div>
                      {
                        order?.shippingPrice?.amount > 0 &&
                        (<div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                          <dt className=" whitespace-nowrap">Envío</dt>
                          <dd className="md:mt-1 whitespace-nowrap">{printPriceWithCommasAndPeriods(order?.shippingPrice?.amount) + " " + order?.shippingPrice?.codeCurrency}</dd>
                        </div>)
                      }
                      <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                        <dt >Total</dt>
                        <dd className="md:mt-1">{printPriceWithCommasAndPeriods(order?.totalToPay[0]?.amount) + " " + order?.totalToPay[0]?.codeCurrency}</dd>
                      </div>

                      <div className="col-span-2">
                        <div className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-2 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                          <div className=" col-span-1  pt-4 md:block md:pt-0">
                            <dt className="font-medium text-gray-900 mt-2  ">Creada</dt>
                            <dd className="md:mt-1">
                              <time className="text-gray-500 font-semibold" dateTime={order.createdAt}>{formatDateTime(order.createdAt)}</time>
                            </dd>
                          </div>

                          <div className=" col-span-1  pt-4 font-medium text-gray-900 md:block md:pt-0">
                            <dt className="mt-2  whitespace-nowrap">Estado</dt>
                            <dd className="md:mt-1 text-gray-500 font-semibold  whitespace-nowrap">{filterOrdenStatus(order?.status)}</dd>
                          </div>
                        </div>
                      </div>
                    </dl>
                    <div className="-mb-6 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                      {order.selledProducts.map((product) => {


                        return (
                          <div key={product.id} className="py-6 sm:flex sm:flex-col">
                            <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                              <img
                                src={product.image?.src ? product.image.src : imageDefault}
                                alt={product.image?.src ? product.image.src : imageDefault}
                                className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-32 sm:w-32"
                              />
                              <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                                <h3 className="text-sm font-medium text-gray-900">
                                  {product.name}
                                </h3>
                                <p className="truncate text-sm text-gray-500">
                                  <span>{product.quantity + " U"}</span>
                                </p>
                                <p className="mt-1 font-medium text-gray-900">{product?.priceUnitary?.amount + " " + product?.priceUnitary?.codeCurrency}</p>
                              </div>
                            </div>

                            <div className="flex flex-col lg:flex-row items-end lg:items-center justify-end">

                              {
                                (order?.paymentGateway?.code === "G_TROPIPAY") && (
                                  <div className="mt-6 sm:mt-4 flex items-center justify-end ml-4">
                                    <button
                                      type="button"
                                      className="flex w-normal whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                      onClick={() => {
                                        CreateTropipayLink(order.id)
                                      }}
                                    >
                                      Volver a generar
                                      link de pago
                                    </button>
                                  </div>
                                )
                              }
                              <div className="mt-6 sm:mt-4 flex items-center justify-end ml-4">
                                <button
                                  type="button"
                                  className="flex w-normal whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                  onClick={() => {
                                    // CreateTropipayLink(order.id)
                                    getOrderDetails(order.id, navigate)
                                  }}
                                >
                                  Ver detalles
                                </button>
                              </div>
                              {
                                (order.status === "PAYMENT_PENDING" || order.status === "CREATED") && (
                                  <div className="mt-6 sm:mt-4 flex items-center justify-end ml-4">
                                    <button
                                      type="button"
                                      className="flex w-32 sm:w-40 whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-600 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                      onClick={() => {
                                        // CancelOrder(order.id as number)
                                        setDeleteOrderModal({
                                          state: true,
                                          id: order.id
                                        })
                                      }}
                                    >
                                      Cancelar orden
                                    </button>
                                  </div>
                                )
                              }
                            </div>

                          </div>
                        )
                      }
                      )}
                    </div>

                  </section>
                ))}
              </div>

          }

        </div>
      </div>
      {
        deleteOrderModal.state && (
          <Modal state={deleteOrderModal.state} close={setDeleteOrderModal}>
            <div className='flex items-center flex-col'>
              <span className="text-center">
                ¿Seguro que desea cancelar la orden?
              </span>

              <button
                className='bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4'
                onClick={(e) => {
                  e.preventDefault()
                  CancelOrder(deleteOrderModal?.id!)
                  setDeleteOrderModal({
                    state: false,
                    id: null
                  })
                }}
              >
                Aceptar
              </button>
            </div>
          </Modal>
        )
      }
    </>
  )
}
export default OrdersHistory
