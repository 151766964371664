/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import Input from "../../components/forms/Input";
import AsyncComboBox from "../../components/forms/AsyncCombobox";
import { faMoneyBill, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import tropiPayLogo from "../../assets/png/logoTropiPay.png";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg";
import {
  SumAddons,
  calculateTotal,
  printPriceWithCommasAndPeriods,
  removeDuplicatesAndNulls,
  scrollToTop,
} from "../../utils/functions";
import { useServerOrders } from "../../services/useServerOrders";
import {
  ConvertCurrencyFunction,
  GenericPriceInterface,
  ObjectWithCode,
  RegionsInterface,
} from "../../interfaces/ServerInterfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useServerForm } from "../../services/useServerForm";
import Select from "../../components/forms/Select";
import Modal from "../../components/modals/GenericModal";
import TextArea from "../../components/forms/TextArea";
import { setTropiPayURL } from "../../store/slices/FormSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LoadingSpin from "../../components/misc/LoadingSpin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import bigDecimal from "js-big-decimal";

interface PaymentWayFormInterface {
  setActualView: any;
  products: Array<ProductCartInterface>;
  subTotal: { prices: number; codeCurrency: string }[];
  setOpen: Function;
  deletedProductFromCart: Function;
  setCartShopping: Function;
  online_shop_main_currency: string;
  enable_pick_up_in_store: boolean;
  when_shop_create_preorder: boolean;
}

export default function PaymentWayForm({
  setActualView,
  products,
  subTotal,
  setOpen,
  deletedProductFromCart,
  setCartShopping,
  online_shop_main_currency,
  enable_pick_up_in_store,
  when_shop_create_preorder,
}: PaymentWayFormInterface) {
  const navigate = useNavigate();

  const { findAllRegions, getPaymentWays } = useServerForm();
  const { availableCurrencies } = useAppSelector((state) => state.session);

  const [regionData, setRegionData] = useState<RegionsInterface[]>([]);
  const [provinces, setProvinces] = useState<ObjectWithCode[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<ObjectWithCode>();
  const [municipalities, setMunicipalities] = useState<ObjectWithCode[]>([]);
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<ObjectWithCode | null>();
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>("");
  const [selectedPrice, setSelectedPrice] = useState<{
    amount: number;
    codeCurrency: string;
  } | null>(null);
  const { currentCurrency, minimun_amount_to_buy_with_delivery } = useSelector(
    (state: RootState) => state.session
  );

  const [tropiPayLoading, setTropiPayLoading] = useState<boolean>(false);

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState<boolean>(false);

  const [billingSameAsShiping, setBillingSameAsShiping] =
    useState<boolean>(true);

  const [pickUpInStore, setPickUpInStore] = useState<boolean>(false);
  const [OnPremiseConsumption, setOnPremiseConsumption] =
    useState<boolean>(false);
  const [takeawayConsuming, setTakeawayConsuming] = useState<boolean>(true);

  const [errorString, setErrorString] = useState<string | null>(null);

  const { regions, paymentWay } = useAppSelector((state) => state.form);

  const { handleSubmit, control, watch, getValues } = useForm();

  const { createNewOrder, createNewPreOrder, isLoading } = useServerOrders();

  const [loading, setLoading] = useState<boolean>(false);

  const [immediateDelivery, setImmediateDelivery] = useState<boolean>(false);
  const [timeForDelivery, setTimeForDelivery] = useState<boolean>(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");

  const [selectedHour, setSelectedHour] = useState("");

  const dispatch = useAppDispatch();

  const mainCurrency = availableCurrencies?.find((e) => e.isMain)?.code!;

  const convertCurrency: ConvertCurrencyFunction = (price, codeCurrency) => {
    if (price.codeCurrency === codeCurrency) return price;

    let targetCurrency = availableCurrencies.find(
      (curr) => curr.code === codeCurrency
    );

    const key: keyof GenericPriceInterface =
      price.price !== undefined ? "price" : "amount";

    //verificar que la moneda especificada existe y esta configurada en el negocio
    if (!targetCurrency) {
      return price;
    }

    let priceInMainCurrency: GenericPriceInterface = price;
    //Get price in mainCurrency
    if (price.codeCurrency !== mainCurrency) {
      const priceAmount = new bigDecimal(price.price || price.amount);
      const currency = availableCurrencies.find(
        (curr) => curr.code === price.codeCurrency
      )!;
      const rate = new bigDecimal(currency?.exchangeRate);
      const amount = priceAmount.multiply(rate).getValue();
      priceInMainCurrency[key] = Number(amount);
      priceInMainCurrency.codeCurrency = mainCurrency!;
    }

    if (codeCurrency === mainCurrency) {
      return {
        [key]: priceInMainCurrency[key],
        codeCurrency: priceInMainCurrency.codeCurrency,
      };
    }

    const targetPrice = Number(
      bigDecimal.divide(priceInMainCurrency[key], targetCurrency?.exchangeRate)
    );

    return { [key]: targetPrice, codeCurrency };
  };

  useEffect(() => {
    dispatch(setTropiPayURL(null));

    const fetchData = async () => {
      await findAllRegions();

      await getPaymentWays();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (products.length < 1) {
      setActualView("shop");
    }
  }, [products]);

  useEffect(() => {
    if (
      minimun_amount_to_buy_with_delivery.find(
        (element) => element.codeCurrency === currentCurrency
      )?.amount! >= subTotal[0]?.prices
    ) {
      setPickUpInStore(true);
    }
  }, [subTotal]);

  useEffect(() => {
    const regionsFiltredBySelectedShopMainCurrency = regions.map((region) => {
      if (region?.price.codeCurrency === online_shop_main_currency) {
        return region;
      } else {
        if (
          availableCurrencies.find((elem) => elem.isMain)?.code ===
          region?.price.codeCurrency
        ) {
          return {
            ...region,
            price: {
              amount:
                region?.price.amount! /
                availableCurrencies?.find(
                  (elem) => elem.code === online_shop_main_currency
                )?.exchangeRate!,
              // amount: convertCurrency({ amount: region?.price.amount!, codeCurrency: region?.price.codeCurrency! }, online_shop_main_currency),
              codeCurrency: online_shop_main_currency,
            },
          };
        } else {
          return {
            ...region,
            price: {
              // amount: (region?.price.amount! / availableCurrencies?.find(elem => elem.code === region?.price.codeCurrency)?.exchangeRate!) * availableCurrencies?.find(elem => elem.code === online_shop_main_currency)?.exchangeRate!,
              amount: convertCurrency(
                {
                  amount: region?.price.amount!,
                  codeCurrency: region?.price.codeCurrency!,
                },
                online_shop_main_currency
              ).amount,
              codeCurrency: online_shop_main_currency,
            },
          };
        }
      }
    });
    const clearProvinces = removeDuplicatesAndNulls(
      //@ts-ignore
      regionsFiltredBySelectedShopMainCurrency
    );
    setRegionData(clearProvinces);

    const uniqueProvinces = [
      ...new Set(clearProvinces.map((item) => JSON.stringify(item.province))),
    ].map((province) => JSON.parse(province));
    setProvinces(
      uniqueProvinces.filter((value) => value !== undefined && value !== null)
    );
    setSelectedMunicipality(null);
  }, [regions]);

  useEffect(() => {
    const clearMunucipalities = regionData
      .filter((item) => item.province?.name === selectedProvince?.name)
      .filter((value) => value !== undefined && value !== null)
      .map((item) => item.municipality)
      .filter((value) => value?.name !== undefined && value?.name !== null);

    const uniqueMunicipalities = [
      ...new Set(clearMunucipalities.map((item) => JSON.stringify(item))),
    ].map((municipality) => JSON.parse(municipality));
    setMunicipalities(uniqueMunicipalities);
    // setSelectedMunicipality('')
    setSelectedCity(null);
  }, [selectedProvince, regionData]);

  useEffect(() => {
    const filteredCities = regionData
      .filter(
        (item) =>
          item.province?.name === selectedProvince?.name &&
          item.municipality?.name === selectedMunicipality?.name
      )
      .map((item) => item.name);

    const uniqueCities = filteredCities.filter(
      (value, index) => filteredCities.indexOf(value) === index
    );
    if (uniqueCities.length === 1) {
      setSelectedCity(uniqueCities[0]);
    }
    setCities(uniqueCities);
  }, [selectedProvince, selectedMunicipality, regionData]);

  useEffect(() => {
    const selectedData = regionData.find(
      (item) =>
        item.province?.name === selectedProvince?.name &&
        item.municipality?.name === selectedMunicipality?.name &&
        item.name === selectedCity
    );
    setSelectedPrice(selectedData ? selectedData.price : null);
  }, [selectedProvince, selectedMunicipality, selectedCity, regionData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handlePaymentOptionSelect = (option: SetStateAction<string>) => {
    setSelectedPaymentOption(option);
  };

  // const discount = { code: 'CHEAPSKATE', amount: '$24.00' }
  const shipping = {
    prices: selectedPrice?.amount,
    codeCurrency: selectedPrice?.codeCurrency,
  };

  const total = calculateTotal(subTotal, shipping);

  const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
    values
  ) => {
    if (
      pickUpInStore === false &&
      OnPremiseConsumption === false &&
      takeawayConsuming === false
    ) {
      toast.error("Seleccione el método de consumo");

      scrollToTop();
    } else {
      const {
        shiping_firstName,
        shiping_lastName,
        shiping_street_1,
        shiping_street_2,
        shiping_phone,
        shiping_email,

        customerNotes,

        billing_firstName,
        billing_lastName,
        billing_street_1,
        billing_street_2,
        billing_provinceId,
        billing_countryId,
        billing_municipalityId,
        billing_city,
        billing_phone,
        billing_email,
      } = values;

      if (when_shop_create_preorder) {
        const data = {
          products: products.map((prod) => ({
            productId: prod.id,
            quantity: prod.quantity,
            ...(prod.variationId !== null && { variationId: prod.variationId }),
            observations: prod.observations.replace(/\s+/g, " ").trim(),
          })),

          pickUpInStore: pickUpInStore || OnPremiseConsumption,

          observations: pickUpInStore
            ? "Consumo exclusivo en el local"
            : OnPremiseConsumption
              ? "Para llevar consumiendo"
              : takeawayConsuming && "Para domicilio",
          customerNote: customerNotes ? customerNotes : "",

          //Optional coupons
          //"coupons": ["PEPE"],

          //Optional shippingPrice
          shippingPrice:
            pickUpInStore || OnPremiseConsumption
              ? null
              : {
                amount: shipping.prices,
                codeCurrency: shipping.codeCurrency,
                // codeCurrency: "USD"
              },

          shipping: {
            street_1: pickUpInStore ? null : shiping_street_1,
            street_2: pickUpInStore
              ? null
              : shiping_street_2
                ? shiping_street_2
                : "",
            firstName: pickUpInStore ? null : shiping_firstName,
            lastName: pickUpInStore ? null : shiping_lastName,
            postalCode: pickUpInStore ? null : "",
            phone: JSON.stringify(shiping_phone),
            email: pickUpInStore ? null : shiping_email,
            description: pickUpInStore ? null : "",
            city: pickUpInStore ? null : selectedCity,
            municipalityId: pickUpInStore ? null : selectedMunicipality?.id,
            provinceId: pickUpInStore ? null : selectedProvince?.id,
            countryId: pickUpInStore ? null : 54,
          },
          billing: {
            street_1: pickUpInStore
              ? billing_street_1
              : billingSameAsShiping
                ? shiping_street_1
                : billing_street_1,
            street_2: pickUpInStore
              ? billing_street_2
              : billingSameAsShiping
                ? shiping_street_2
                : billing_street_2,
            firstName: pickUpInStore
              ? billing_firstName
              : billingSameAsShiping
                ? shiping_firstName
                : billing_firstName,
            lastName: pickUpInStore
              ? billing_lastName
              : billingSameAsShiping
                ? shiping_lastName
                : billing_lastName,
            city: pickUpInStore
              ? billing_city
              : billingSameAsShiping
                ? selectedCity
                : billing_city,
            postalCode: "",
            phone: JSON.stringify(
              pickUpInStore || OnPremiseConsumption
                ? billing_phone
                : billingSameAsShiping
                  ? shiping_phone
                  : billing_phone
            ),
            email: pickUpInStore
              ? billing_email
              : billingSameAsShiping
                ? shiping_email
                : billing_email,
            description: "",
            municipalityId: pickUpInStore
              ? billing_municipalityId
              : billingSameAsShiping
                ? selectedMunicipality?.id
                : billing_municipalityId,
            provinceId: pickUpInStore
              ? billing_provinceId
              : billingSameAsShiping
                ? selectedProvince?.id
                : billing_provinceId,
            countryId: pickUpInStore
              ? billing_countryId
              : billingSameAsShiping
                ? 54
                : billing_countryId,
          },
          deliveryAt: immediateDelivery
            ? moment().format("YYYY-MM-DD HH:mm")
            : moment()
              .set({ hour: Number.parseInt(selectedHour!) })
              .format("YYYY-MM-DD HH:mm"),
        };

        createNewPreOrder(data, location.pathname, navigate);

        if (takeawayConsuming) {
          if (immediateDelivery || timeForDelivery) {
            createNewOrder(data, location.pathname, navigate);
          } else {
            toast.error("Seleccione fecha y hora de entrega");
            scrollToTop();
          }
        } else {
          createNewPreOrder(data, location.pathname, navigate);
        }
      } else {
        if (selectedPaymentOption !== "") {
          const data = {
            paymentWayCode:
              selectedPaymentOption === "opcion1"
                ? paymentWay.find((way) => way?.code === "G_COD")?.code
                : selectedPaymentOption === "opcion2"
                  ? paymentWay.find((way) => way?.code === "G_CHEQUE")?.code
                  : selectedPaymentOption === "opcion3" &&
                  paymentWay.find((way) => way?.code === "G_TROPIPAY")?.code,
            products: products.map((prod) => ({
              productId: prod.id,
              quantity: prod.quantity,
              ...(prod.variationId !== null && {
                variationId: prod.variationId,
              }),
              observations: prod.observations.replace(/\s+/g, " ").trim(),
            })),
            paymentGatewayId:
              selectedPaymentOption === "opcion1"
                ? paymentWay.find((way) => way?.code === "G_COD")?.id
                : selectedPaymentOption === "opcion2"
                  ? paymentWay.find((way) => way?.code === "G_CHEQUE")?.id
                  : selectedPaymentOption === "opcion3" &&
                  paymentWay.find((way) => way?.code === "G_TROPIPAY")?.id,

            pickUpInStore: pickUpInStore || OnPremiseConsumption,

            observations: pickUpInStore
              ? "Consumo exclusivo en el local"
              : OnPremiseConsumption
                ? "Para llevar consumiendo"
                : takeawayConsuming && "Para domicilio",
            customerNote: customerNotes ? customerNotes : "",

            //Optional coupons
            //"coupons": ["PEPE"],

            //Optional shippingPrice
            shippingPrice:
              pickUpInStore || OnPremiseConsumption
                ? null
                : {
                  amount: shipping.prices,
                  codeCurrency: shipping.codeCurrency,
                  // codeCurrency: "USD"
                },

            shipping: {
              street_1: pickUpInStore ? null : shiping_street_1,
              street_2: pickUpInStore
                ? null
                : shiping_street_2
                  ? shiping_street_2
                  : "",
              firstName: pickUpInStore ? null : shiping_firstName,
              lastName: pickUpInStore ? null : shiping_lastName,
              postalCode: pickUpInStore ? null : "",
              phone: JSON.stringify(shiping_phone),
              email: pickUpInStore ? null : shiping_email,
              description: pickUpInStore ? null : "",
              city: pickUpInStore ? null : selectedCity,
              municipalityId: pickUpInStore ? null : selectedMunicipality?.id,
              provinceId: pickUpInStore ? null : selectedProvince?.id,
              countryId: pickUpInStore ? null : 54,
            },
            billing: {
              street_1: pickUpInStore
                ? billing_street_1
                : billingSameAsShiping
                  ? shiping_street_1
                  : billing_street_1,
              street_2: pickUpInStore
                ? billing_street_2
                : billingSameAsShiping
                  ? shiping_street_2
                  : billing_street_2,
              firstName: pickUpInStore
                ? billing_firstName
                : billingSameAsShiping
                  ? shiping_firstName
                  : billing_firstName,
              lastName: pickUpInStore
                ? billing_lastName
                : billingSameAsShiping
                  ? shiping_lastName
                  : billing_lastName,
              city: pickUpInStore
                ? billing_city
                : billingSameAsShiping
                  ? selectedCity
                  : billing_city,
              postalCode: "",
              phone: JSON.stringify(
                pickUpInStore || OnPremiseConsumption
                  ? billing_phone
                  : billingSameAsShiping
                    ? shiping_phone
                    : billing_phone
              ),
              email: pickUpInStore
                ? billing_email
                : billingSameAsShiping
                  ? shiping_email
                  : billing_email,
              description: "",
              municipalityId: pickUpInStore
                ? billing_municipalityId
                : billingSameAsShiping
                  ? selectedMunicipality?.id
                  : billing_municipalityId,
              provinceId: pickUpInStore
                ? billing_provinceId
                : billingSameAsShiping
                  ? selectedProvince?.id
                  : billing_provinceId,
              countryId: pickUpInStore
                ? billing_countryId
                : billingSameAsShiping
                  ? 54
                  : billing_countryId,
            },
            deliveryAt: immediateDelivery
              ? moment().format("YYYY-MM-DD HH:mm")
              : moment()
                .set({ hour: Number.parseInt(selectedHour!) })
                .format("YYYY-MM-DD HH:mm"),
          };

          if (takeawayConsuming) {
            if (immediateDelivery || timeForDelivery) {
              // console.log(data);
              createNewOrder(data, location.pathname, navigate);
            } else {
              toast.error("Seleccione fecha y hora de entrega");
              scrollToTop();
            }
          } else {
            // console.log(data);
            createNewOrder(data, location.pathname, navigate);
          }
        } else {
          setErrorString("Seleccione un método de pago");

          setTimeout(() => {
            setErrorString(null);
          }, 3000);
        }
      }
    }
  };

  const currentHour = moment().hour();
  const startHour = currentHour % 24; // Start 2 hours after the current hour
  const currentHourPlusTwo = moment({ hour: (startHour + 2) % 24 }).format(
    "HH:mm"
  );
  const hours = [];

  for (let i = 0; i < 24; i++) {
    hours.push(moment({ hour: (startHour + i) % 24 }).format("HH:mm"));
  }

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSelectedHour(event.target.value);
  };

  return (
    <>
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden transition-all">
        <div className="px-4 py-6 sm:px-6 lg:hidden">
          <div className="mx-auto flex max-w-lg">
            <a href="#">
              <span className="sr-only">Your Company</span>
              <img
                src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=600"
                alt=""
                className="h-8 w-auto"
              />
            </a>
          </div>
        </div>

        <h1 className="sr-only">Checkout</h1>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex"
        >
          <h2 id="summary-heading" className="sr-only">
            Order summary
          </h2>

          <ul
            role="list"
            className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6 pt-20"
          >
            {products.map((product) => (
              <li key={product.id} className="flex space-x-6 py-6">
                <img
                  src={product.images ? product.images.src : imageDefault}
                  alt={product.images ? product.images.src : imageDefault}
                  className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                />
                <div className="flex flex-col justify-between space-y-4">
                  <div className="space-y-1 text-sm font-medium">
                    <h3 className="text-gray-900">{product.name}</h3>
                    <p className="text-gray-900">
                      {product.price.price} {product.price.codeCurrency}
                    </p>
                    {product?.addons?.length! > 0 && (
                      <div className="w-full">
                        <p className="text-gray-500 text-sm text-start">
                          + Agregos
                        </p>

                        <p>
                          {printPriceWithCommasAndPeriods(
                            SumAddons(product?.addons!, currentCurrency!) *
                            product.quantity
                          ) +
                            " " +
                            product?.price?.codeCurrency}
                        </p>

                        <p className="text-gray-500 text-sm text-start border-t-2 border-gray-500">
                          Total unitario
                        </p>

                        <p
                          className={` ${product.onSale && "line-through text-slate-500"
                            }`}
                        >
                          {`${printPriceWithCommasAndPeriods(
                            (product.price.price +
                              SumAddons(product?.addons!, currentCurrency!)) *
                            product.quantity
                          )} ${product.price.codeCurrency}`}
                        </p>
                      </div>
                    )}
                    <p className="text-gray-500">{product.quantity} U</p>
                    {/* <p className="text-gray-500">{product.size}</p> */}
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setOpen(true)}
                      type="button"
                      className="text-sm font-medium text-cyan-600 hover:text-cyan-500"
                    >
                      Editar
                    </button>
                    <div className="flex border-l border-gray-300 pl-4">
                      <button
                        onClick={() => deletedProductFromCart(product)}
                        type="button"
                        className="text-sm font-medium text-cyan-600 hover:text-cyan-500"
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
            <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">
                  {subTotal.map((st, key) => (
                    <p className="" key={key}>
                      {printPriceWithCommasAndPeriods(st.prices!)}{" "}
                      {st.codeCurrency}
                    </p>
                  ))}
                </dd>
              </div>

              {!pickUpInStore && (
                <div className="flex justify-between">
                  <dt>Envío</dt>
                  <dd className="text-gray-900">
                    {shipping.prices !== undefined && shipping?.prices > 0
                      ? printPriceWithCommasAndPeriods(shipping.prices) +
                      " " +
                      shipping.codeCurrency
                      : "Introduzca la dirección de envío"}
                  </dd>
                </div>
              )}

              <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-base">
                  {total.map((st, key) => {
                    if (st.prices !== undefined) {
                      return (
                        <p className="" key={key}>
                          {printPriceWithCommasAndPeriods(st.prices)}{" "}
                          {st.codeCurrency}
                        </p>
                      );
                    }
                  })}
                </dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0 mt-0 lg:mt-28"
        >
          <div className="mx-auto max-w-lg">
            <div className="flex items-center justify-start">
              <img
                className="mr-4 h-10 w-auto"
                src="/El-Gelato-Logo-Colores-1.png"
                alt="gelatoLogo"
              />
              <h1 className="text-2xl font-bold tracking-tight text-blue-950 sm:text-3xl">
                Finaliza tu compra
              </h1>
            </div>

            <form
              method="POST"
              className={`${pickUpInStore ? "" : "space-y-6"}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                <div className="flex flex-col">
                  {enable_pick_up_in_store && (
                    <div className="mt-6 flex space-x-2 col-span-full mb-5">
                      <div className="flex h-5 w-full items-center justify-start">
                        <input
                          id="pickUpInStore"
                          name="pickUpInStore"
                          type="checkbox"
                          defaultChecked={false}
                          checked={pickUpInStore}
                          onClick={() => {
                            setPickUpInStore(!pickUpInStore);
                            setOnPremiseConsumption(false);
                            setTakeawayConsuming(false);
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                          disabled={
                            minimun_amount_to_buy_with_delivery.find(
                              (element) =>
                                element.codeCurrency === currentCurrency
                            )?.amount! >= subTotal[0]?.prices
                          }
                        />
                        <label
                          htmlFor="pickUpInStore"
                          className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                        >
                          Consumo exclusivo en el local
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="flex space-x-2 col-span-full mb-5">
                    <div className="flex h-5 w-full items-center justify-start">
                      <input
                        id="OnPremiseConsumption"
                        name="OnPremiseConsumption"
                        type="checkbox"
                        defaultChecked={OnPremiseConsumption}
                        checked={OnPremiseConsumption}
                        onClick={() => {
                          setOnPremiseConsumption(!OnPremiseConsumption);
                          setPickUpInStore(false);
                          setTakeawayConsuming(false);
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                        disabled={
                          minimun_amount_to_buy_with_delivery.find(
                            (element) =>
                              element.codeCurrency === currentCurrency
                          )?.amount! >= subTotal[0]?.prices
                        }
                      />
                      <label
                        htmlFor="OnPremiseConsumption"
                        className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                      >
                        Para llevar consumiendo
                      </label>
                    </div>
                  </div>

                  <div className="flex space-x-2 col-span-full mb-10">
                    <div className="flex h-5 w-full items-center justify-start">
                      <input
                        id="takeawayConsuming"
                        name="takeawayConsuming"
                        type="checkbox"
                        defaultChecked={takeawayConsuming}
                        checked={takeawayConsuming}
                        onClick={() => {
                          setTakeawayConsuming(!takeawayConsuming);
                          setOnPremiseConsumption(false);
                          setPickUpInStore(false);
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                        disabled={
                          minimun_amount_to_buy_with_delivery.find(
                            (element) =>
                              element.codeCurrency === currentCurrency
                          )?.amount! >= subTotal[0]?.prices
                        }
                      />
                      <label
                        htmlFor="takeawayConsuming"
                        className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                      >
                        Para domicilio
                      </label>
                    </div>
                  </div>
                </div>

                {minimun_amount_to_buy_with_delivery.find(
                  (element) => element.codeCurrency === currentCurrency
                )?.amount! >= subTotal[0]?.prices && (
                    <p className="text-white bg-red-500 p-2 rounded-md opacity-80 col-span-6 mb-8">
                      Su orden no es mayor que{" "}
                      {printPriceWithCommasAndPeriods(
                        minimun_amount_to_buy_with_delivery.find(
                          (element) => element.codeCurrency === currentCurrency
                        )?.amount!
                      ) +
                        " " +
                        minimun_amount_to_buy_with_delivery.find(
                          (element) => element.codeCurrency === currentCurrency
                        )?.codeCurrency}{" "}
                      para poder solicitar mensajería en la compra.
                    </p>
                  )}

                {((pickUpInStore === false &&
                  OnPremiseConsumption === false &&
                  takeawayConsuming === false) ||
                  (pickUpInStore === false &&
                    OnPremiseConsumption === false &&
                    takeawayConsuming === true)) && (
                    <>
                      <h1 className="col-span-full text-xl font-semibold tracking-tight text-cyan-600 sm:text-xl lg:mt-0">
                        Datos de la dirección de envío
                      </h1>

                      <div className="sm:col-span-3">
                        <Input
                          name="shiping_firstName"
                          control={control}
                          label="Nombre *"
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <Input
                          name="shiping_lastName"
                          control={control}
                          label="Apellido *"
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>

                      <div className="col-span-full">
                        <Input
                          name="shiping_street_1"
                          control={control}
                          label="Dirección completa *"
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>

                      <div className="col-span-full">
                        <Input
                          name="shiping_street_2"
                          control={control}
                          label=""
                          placeholder="Apartamento, habitación, etc. (opcional)"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <Input
                          name="shiping_countryId"
                          control={control}
                          label="País"
                          placeholder="Cuba"
                          disabled={true}
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <Select
                          onChange={(e: { name: string }) => {
                            setSelectedProvince(
                              provinces.filter(
                                (province) => province.name === e.name
                              )[0]
                            );
                            setSelectedMunicipality(null);
                            setSelectedCity(null);
                          }}
                          data={provinces}
                          label="Provincia *"
                          control={control}
                          name="provinces"
                          defaultValue={undefined}
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <Select
                          value={{
                            id: selectedMunicipality?.id,
                            name: selectedMunicipality?.name,
                          }}
                          onChange={(e: { name: string }) => {
                            setSelectedMunicipality(
                              municipalities.filter(
                                (munipality) => munipality.name === e.name
                              )[0]
                            );
                            setSelectedCity(null);
                          }}
                          data={municipalities}
                          label="Municipio *"
                          control={control}
                          name="municipies"
                          defaultValue={undefined}
                          rules={{ required: "Campo requerido" }}
                          noOptionsText="Seleccione provincia"
                        />
                      </div>

                      <div className="sm:col-span-3">
                        <Select
                          value={{
                            id: cities.length === 1 ? cities[0] : selectedCity,
                            name: cities.length === 1 ? cities[0] : selectedCity,
                          }}
                          onChange={(e: { name: string }) =>
                            setSelectedCity(e.name)
                          }
                          data={cities.map((city, index) => ({
                            name: city,
                            id: index,
                          }))}
                          label="Localidad *"
                          control={control}
                          name="cities"
                          defaultValue={undefined}
                          rules={{ required: "Campo requerido" }}
                          noOptionsText="Seleccione municipio"
                        />
                      </div>

                      <div className="col-span-full">
                        <Input
                          name="shiping_phone"
                          control={control}
                          label="Número de teléfono *"
                          rules={{ required: "Campo requerido" }}
                          type="number"
                        />
                      </div>

                      <div className="col-span-full">
                        <Input
                          name="shiping_email"
                          control={control}
                          label="Correo electrónico *"
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>

                      {/* <div className="col-span-full">
                        <DateInput
                          name={"deliveryAt"}
                          label={"Fecha y hora de entrega"}
                          control={control}
                          rules={{ required: "Este campo es requerido" }}
                          includeTime
                          fromToday
                        // fromCustom="2023-10-01"
                        />
                      </div> */}

                      <div className="flex flex-col">
                        <label
                          htmlFor="label"
                          className="block text-sm font-medium text-gray-700 whitespace-nowrap mb-5"
                        >
                          Fecha y hora de entrega
                        </label>

                        <div className="flex">
                          <div className="flex space-x-2 col-span-full mb-5 mx-4">
                            <div className="flex h-5 w-full items-center justify-start">
                              <input
                                id="immediateDelivery"
                                name="immediateDelivery"
                                type="checkbox"
                                defaultChecked={immediateDelivery}
                                checked={immediateDelivery}
                                onClick={() => {
                                  setImmediateDelivery(!immediateDelivery);
                                  setTimeForDelivery(false);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                              />
                              <label
                                htmlFor="immediateDelivery"
                                className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                              >
                                Entrega inmediata
                              </label>
                            </div>
                          </div>

                          <div className="flex flex-col justify-start items-center">
                            <div className="flex space-x-2 col-span-full mb-5 mx-4">
                              <div className="flex h-5 w-full items-center justify-start">
                                <input
                                  id="timeForDelivery"
                                  name="timeForDelivery"
                                  type="checkbox"
                                  defaultChecked={timeForDelivery}
                                  checked={timeForDelivery}
                                  onClick={() => {
                                    setTimeForDelivery(!timeForDelivery);
                                    setImmediateDelivery(false);
                                  }}
                                  className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                                  disabled={
                                    minimun_amount_to_buy_with_delivery.find(
                                      (element) =>
                                        element.codeCurrency === currentCurrency
                                    )?.amount! >= subTotal[0]?.prices
                                  }
                                />
                                <label
                                  htmlFor="timeForDelivery"
                                  className="cursor-pointer whitespace-nowrap ml-4 text-md font-medium text-gray-900"
                                >
                                  Entrega programada
                                </label>
                              </div>
                            </div>

                            {timeForDelivery && (
                              <div className=" flex justify-center items-center">
                                <p className="mr-2">Hoy</p>
                                <div>
                                  {/* <h1 className="text-lg font-semibold mb-2 text-center">Seleccione una Hora</h1> */}
                                  <select
                                    className="p-2 bg-white rounded shadow w-40"
                                    value={selectedHour}
                                    onChange={handleChange}
                                  >
                                    {hours.map((hour, index) => (
                                      <option
                                        className={`${Number.parseInt(hour) <
                                          Number.parseInt(currentHourPlusTwo)
                                          ? "bg-slate-300"
                                          : ""
                                          }`}
                                        disabled={
                                          Number.parseInt(hour) <
                                          Number.parseInt(currentHourPlusTwo)
                                        }
                                        key={index}
                                        value={hour}
                                      >
                                        {Number.parseInt(hour) >= 12
                                          ? Number.parseInt(hour) - 12
                                          : Number.parseInt(hour)}{" "}
                                        {Number.parseInt(hour) > 12 ? "PM" : "AM"}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 flex space-x-2 col-span-full">
                        <div className="flex h-5 items-center">
                          <input
                            id="billingSameAsShiping"
                            name="billingSameAsShiping"
                            type="checkbox"
                            defaultChecked={billingSameAsShiping}
                            onClick={() =>
                              setBillingSameAsShiping(!billingSameAsShiping)
                            }
                            className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                          />
                        </div>
                        <label
                          htmlFor="billingSameAsShiping"
                          className="lg:whitespace-nowrap text-sm font-medium text-gray-900"
                        >
                          Los datos de facturación son los mismos que los de envío
                        </label>
                      </div>
                    </>
                  )}
              </div>

              {(!billingSameAsShiping ||
                pickUpInStore ||
                OnPremiseConsumption) && (
                  <div
                    className={`${!pickUpInStore || OnPremiseConsumption ? "mt-10" : ""
                      } grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6`}
                  >
                    <h1 className="sm:col-span-4 text-xl font-semibold tracking-tight text-cyan-600 sm:text-xl lg:mt-0">
                      Introduzca sus datos de facturación
                    </h1>


                    <div className={(!pickUpInStore && !OnPremiseConsumption) ? "sm:col-span-3" : "col-span-full"}>
                      <Input
                        name="billing_firstName"
                        control={control}
                        label="Nombre *"
                        rules={{ required: "Campo requerido" }}
                      />
                    </div>

                    {!pickUpInStore && !OnPremiseConsumption && (
                      <>
                        <div className="sm:col-span-3">
                          <Input
                            name="billing_lastName"
                            control={control}
                            label="Apellido *"
                            rules={{ required: "Campo requerido" }}
                          />
                        </div>

                        <div className="col-span-full">
                          <Input
                            name="billing_street_1"
                            control={control}
                            label="Dirección completa *"
                            rules={{ required: "Campo requerido" }}
                          />
                        </div>
                        <div className="col-span-full">
                          <Input
                            name="billing_street_2"
                            control={control}
                            label=""
                            placeholder="Apartamento, habitación, etc. (opcional)"
                          />
                        </div>

                        <div className="sm:col-span-3">
                          <AsyncComboBox
                            name="billing_countryId"
                            label="País *"
                            control={control}
                            dataQuery={{ url: "/public/countries" }}
                            normalizeData={{ id: "id", name: "name" }}
                            rules={{ required: "Campo requerido" }}
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <AsyncComboBox
                            name="billing_provinceId"
                            label="Provincia *"
                            control={control}
                            dataQuery={{ url: "/public/provinces" }}
                            normalizeData={{ id: "id", name: "name" }}
                            dependendValue={{
                              countryId:
                                watch("billing.billing_countryId") ??
                                getValues("billing_countryId"),
                            }}
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <AsyncComboBox
                            name="billing_municipalityId"
                            label="Municipio *"
                            control={control}
                            dataQuery={{ url: "/public/municipalities" }}
                            normalizeData={{ id: "id", name: "name" }}
                            dependendValue={{
                              provinceId:
                                watch("billing.billing_provinceId") ??
                                getValues("billing_provinceId"),
                            }}
                          />
                        </div>

                        <div className="sm:col-span-3">
                          <Input
                            name="billing_city"
                            control={control}
                            label="Ciudad"
                          />
                        </div>
                      </>
                    )}

                    <div className="col-span-full">
                      <Input
                        name="billing_phone"
                        control={control}
                        label="Número de teléfono *"
                        rules={{ required: "Campo requerido" }}
                        type="number"
                      />
                    </div>

                    {!pickUpInStore && !OnPremiseConsumption && (
                      <div className="col-span-full">
                        <Input
                          name="billing_email"
                          control={control}
                          label="Correo electrónico *"
                          rules={{ required: "Campo requerido" }}
                        />
                      </div>
                    )}
                  </div>
                )}

              {/* Resumen de productos comprados en vista mobile  */}
              <section
                aria-labelledby="order-heading"
                className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
              >
                <Disclosure as="div" className="mx-auto max-w-lg">
                  {({ open }) => (
                    <>
                      <div className="flex items-center justify-between">
                        <h2
                          id="order-heading"
                          className="text-lg font-medium text-gray-900"
                        >
                          Tu orden
                        </h2>
                        <Disclosure.Button className="font-medium text-cyan-600 hover:text-cyan-500">
                          {open ? (
                            <span>Ocultar resumen completo</span>
                          ) : (
                            <span>Mostrar resumen completo</span>
                          )}
                        </Disclosure.Button>
                      </div>

                      <Disclosure.Panel>
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 border-b border-gray-200"
                        >
                          {products.map((product) => (
                            <li
                              key={product.id}
                              className="flex space-x-6 py-6"
                            >
                              <img
                                src={
                                  product.images
                                    ? product.images.src
                                    : imageDefault
                                }
                                alt={
                                  product.images
                                    ? product.images.src
                                    : imageDefault
                                }
                                className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                              />
                              <div className="flex flex-col justify-between space-y-4">
                                <div className="space-y-1 text-sm font-medium">
                                  <h3 className="text-gray-900">
                                    {product.name}
                                  </h3>
                                  <p className="text-gray-900">
                                    {product.price.price}{" "}
                                    {product.price.codeCurrency}
                                  </p>
                                  {product?.addons?.length! > 0 && (
                                    <div className="w-full md:w-1/3">
                                      <p className="text-gray-500 text-sm text-start">
                                        + Agregos
                                      </p>

                                      <p>
                                        {printPriceWithCommasAndPeriods(
                                          SumAddons(
                                            product?.addons!,
                                            currentCurrency!
                                          ) * product.quantity
                                        ) +
                                          " " +
                                          product?.price?.codeCurrency}
                                      </p>

                                      <p className="text-gray-500 text-sm text-start border-t-2 border-gray-500">
                                        Total unitario
                                      </p>

                                      <p
                                        className={` ${product.onSale &&
                                          "line-through text-slate-500"
                                          }`}
                                      >
                                        {`${printPriceWithCommasAndPeriods(
                                          (product.price.price +
                                            SumAddons(
                                              product?.addons!,
                                              currentCurrency!
                                            )) *
                                          product.quantity
                                        )} ${product.price.codeCurrency}`}
                                      </p>
                                    </div>
                                  )}
                                  <p className="text-gray-500">
                                    {product.quantity} U
                                  </p>
                                  <p className="text-gray-500">
                                    {product.observations ?? ""}
                                  </p>
                                  {/* <p className="text-gray-500">{product.size}</p> */}
                                </div>
                                <div className="flex space-x-4">
                                  <button
                                    onClick={() => setOpen(true)}
                                    type="button"
                                    className="text-sm font-medium text-cyan-600 hover:text-cyan-500"
                                  >
                                    Editar
                                  </button>
                                  <div className="flex border-l border-gray-300 pl-4">
                                    <button
                                      onClick={() =>
                                        deletedProductFromCart(product)
                                      }
                                      type="button"
                                      className="text-sm font-medium text-cyan-600 hover:text-cyan-500"
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                          <div className="flex justify-between">
                            <dt>Subtotal</dt>
                            <dd className="text-gray-900">
                              {subTotal.map((st, key) => (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              ))}
                            </dd>
                          </div>

                          {!pickUpInStore && (
                            <div className="flex justify-between">
                              <dt>Envío</dt>
                              <dd className="text-gray-900">
                                {printPriceWithCommasAndPeriods(
                                  shipping.prices!
                                )}{" "}
                                {shipping.codeCurrency}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </Disclosure.Panel>

                      <p className="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                        <span className="text-base">Total</span>
                        <span className="text-base">
                          {total.map((st, key) => {
                            if (st.prices !== undefined) {
                              return (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              );
                            }
                          })}
                        </span>
                      </p>
                    </>
                  )}
                </Disclosure>
              </section>

              <div className="col-span-full">
                <TextArea
                  name="customerNotes"
                  control={control}
                  label="Notas del pedido (opcional)"
                />
              </div>

              {/* Forma de pago */}
              {!when_shop_create_preorder && (
                <>
                  <div className="flex justify-between items-center w-9/12">
                    <h1 className="text-xl font-semibold tracking-tight text-cyan-600 sm:text-xl mt-6 lg:mt-0">
                      Forma de pago
                    </h1>

                    {errorString !== null && (
                      <p className="mt-6 flex justify-center text-sm font-medium text-red-500">{`* ${errorString}`}</p>
                    )}
                  </div>

                  <div>
                    {paymentWay.length === 0 && <LoadingSpin color="black" />}

                    {paymentWay.some((way) => way?.code === "G_COD") && (
                      <div
                        className="my-6"
                        onClick={() => handlePaymentOptionSelect("opcion1")}
                      >
                        <input
                          type="checkbox"
                          value="opcion1"
                          checked={selectedPaymentOption === "opcion1"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                        />
                        <label className="cursor-pointer hover:font-semibold">
                          Efectivo a la entrega
                        </label>
                      </div>
                    )}

                    {paymentWay.some((way) => way?.code === "G_CHEQUE") && (
                      <div
                        className="my-6"
                        onClick={() => handlePaymentOptionSelect("opcion2")}
                      >
                        <input
                          type="checkbox"
                          value="opcion2"
                          checked={selectedPaymentOption === "opcion2"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                        />
                        <label className="cursor-pointer hover:font-semibold">
                          Transfermovil / Enzona
                        </label>
                      </div>
                    )}

                    {paymentWay.some((way) => way?.code === "G_TROPIPAY") && (
                      <div
                        className="my-6 flex items-center"
                        onClick={() => {
                          if (
                            !total.some((item) => item.codeCurrency === "CUP")
                          ) {
                            handlePaymentOptionSelect("opcion3");
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          value="opcion3"
                          checked={selectedPaymentOption === "opcion3"}
                          className="h-4 w-4 mr-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                          disabled={total.some(
                            (item) => item.codeCurrency === "CUP"
                          )}
                        />
                        <label
                          className={`${!total.some(
                            (item) => item.codeCurrency === "CUP"
                          ) && "hover:cursor-pointer"
                            } hover:font-semibold flex`}
                        >
                          <img
                            src={tropiPayLogo}
                            alt="tropiPayLogo"
                            className="h-8 w-auto"
                          />
                        </label>

                        {total.some((item) => item.codeCurrency === "CUP") && (
                          <p className="text-red-500 ml-4 text-center">
                            (*Pagos en CUP no disponibles)
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* Terminos y condiciones */}
              <div className="my-8 flex space-x-2 justify-start items-start border-t-2 pt-2">
                <div className="flex h-5 items-center">
                  <input
                    id="termsAndConditionsAccepted"
                    name="termsAndConditionsAccepted"
                    type="checkbox"
                    defaultChecked={termsAndConditionsAccepted}
                    onClick={() =>
                      setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
                    }
                    className="h-4 w-4 mt-3 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500 cursor-pointer"
                  />
                </div>
                <label
                  htmlFor="termsAndConditionsAccepted"
                  className="text-sm font-medium text-gray-700 cursor-pointer"
                >
                  He comprobado que los datos son correctos, estoy de acuerdo
                  con los costes y he leído y acepto los términos y condiciones
                  de uso del servicio.
                </label>
              </div>

              <button
                type="submit"
                className={`
                ${loading || !termsAndConditionsAccepted
                    ? "pointer-events-none bg-slate-300 focus:ring-red-300"
                    : `${selectedPaymentOption === "opcion1"
                      ? "bg-green-600 hover:bg-green-700"
                      : selectedPaymentOption === "opcion2"
                        ? "bg-blue-600 hover:bg-blue-700"
                        : selectedPaymentOption === "opcion3"
                          ? "bg-yellow-300 hover:bg-yellow-400"
                          : "bg-cyan-600 hover:bg-cyan-700"
                    }`
                  }
                mt-6 w-full rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2
                
                `}
              >
                {!when_shop_create_preorder ? (
                  <div className="flex justify-center items-center w-full">
                    <p className="mr-4">Pagar</p>

                    {selectedPaymentOption === "opcion1" ? (
                      <FontAwesomeIcon
                        icon={faMoneyBill}
                        className="ml-1 text-white"
                        size="lg"
                        aria-hidden="true"
                      />
                    ) : selectedPaymentOption === "opcion2" ? (
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="ml-1 text-white"
                        size="lg"
                        aria-hidden="true"
                      />
                    ) : (
                      selectedPaymentOption === "opcion3" && (
                        <img
                          src={tropiPayLogo}
                          alt="tropiPayLogo"
                          className="h-6 w-auto"
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="flex justify-center items-center w-full">
                    <p className="mr-4">Solicitar envío</p>
                  </div>
                )}

                {/* {total} */}
              </button>

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <LockClosedIcon
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                ¡Gracias por elegirnos y disfruta de tu compra con total
                seguridad!
              </p>
            </form>
          </div>
        </section>
      </main>

      {tropiPayLoading && (
        <Modal
          state={tropiPayLoading}
          close={() => {
            setTropiPayLoading(false);
            setActualView("shop");
          }}
        >
          <div className="flex items-center flex-col">
            <img src={tropiPayLogo} alt="tropiPayLogo" className="h-8 w-auto" />

            <LoadingSpin color="black" />

            <p className="text-center mt-4">
              Redireccionando a TropiPay para ejecutar el pago
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
